import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddNewUserForm from './NewUserForm';
import { Button } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { LOAD_USERS } from '../GraphQL/Queries';
import { Link } from 'react-router-dom';
import { DELETE_USER_MUTATION } from '../GraphQL/Mutations';
import { toast } from 'react-toastify';

const columns = [
  { id: 'firstName', label: 'First Name', minWidth: 100 },
  { id: 'lastName', label: 'Last Name', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'serviceLine', label: 'Service Line', minWidth: 100 },
  { id: 'squadLead', label: 'Squad Lead', minWidth: 100 },
  { id: 'role', label: 'Role', minWidth: 100 },
];

const useStyles = makeStyles({
  root: {
    width: '80%',
    marginTop: '70px',
    marginLeft: '60px',
  },
  container: {
    maxHeight: 640,
  },
  link: {
    textDecoration: 'none',
  },
  heading_div: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  addUserButton: {
    backgroundColor: '#5584FF',
    color: '#FFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1070e6',
    },
  },
  addPersonIcon: {
    marginRight: '10px',
  },
  editButton: {
    margin: '6px',
  },
});

export default function UsersTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data } = useQuery(LOAD_USERS);
  const [users, setUsers] = useState([]);
  const [deleteUser, { error }] = useMutation(DELETE_USER_MUTATION);
  const [loggedUser] = useState(JSON.parse(localStorage.getItem('user')));

  if (error) {
    console.log(error);
  }

  useEffect(() => {
    if (data) {
      setUsers(data.getUsers);
    }
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteSingleUser = async (userId) => {
    try {
      if (userId !== loggedUser.id) {
        await deleteUser({
          variables: {
            deleteUserInput: {
              id: userId,
            },
          },
        });
        successNotification('User deleted successfully');
        // setTimeout(() => window.location.reload(), 1500);
      } else if (userId === loggedUser.id) {
        errorNotification('Not allowed to self delete');
        console.log(error);
      }
    } catch (error) {
      console.log(error);
      errorNotification("Error accured during user deletion. Can't delete user that has PRRs! ");
    }
  };

  const successNotification = (message) =>
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const errorNotification = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.heading_div}>
          <h1 className={classes.heading}>Users</h1>
          <AddNewUserForm />
        </div>
        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} className={classes.tableRow}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {' '}
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <td>
                        <Button
                          component={Link}
                          to={`/user/${row.id}`}
                          variant="contained"
                          color="primary"
                          className={classes.editButton}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={function () {
                            deleteSingleUser(row.id);
                          }}
                          variant="contained"
                          color="secondary"
                          className={classes.editButton}
                        >
                          Delete
                        </Button>
                      </td>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}
