import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_PRRS, GET_PRR_SUBMITTER } from '../GraphQL/Queries';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Select } from '@material-ui/core';
import { InputBase } from '@material-ui/core';

const columns = [
  { id: 'projectName', label: 'Project name', minWidth: 100 },
  { id: 'accountOwner', label: 'Submitted by', minWidth: 100 },
  { id: 'resolutionStatus', label: 'Resolution Status', minWidth: 100 },
  { id: 'stage', label: 'Stage', minWidth: 100 },
];

const useStyles = makeStyles({
  root: {
    width: '80%',
    marginTop: '70px',
    marginLeft: '60px',
  },
  container: {
    maxHeight: 640,
  },
  link: {
    textDecoration: 'none',
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [projectResourceRequests, setProjectResourceRequests] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [prrFilter, setPrrFilter] = useState('All');
  const [searchValue, setSearchValue] = useState('');
  const [tempPrrs, settempPrrs] = useState(projectResourceRequests);

  const { data: adminPrrs } = useQuery(GET_ALL_PRRS, { skip: user.role === 'Seller' });
  const { data: sellerPrrs } = useQuery(GET_PRR_SUBMITTER, { skip: user.role === 'Admin' });

  const searchData = (searchValue) => {
    //console.log(projectResourceRequests.filter(obj => Object.values(obj).some(value=> obj[value].includes(searchValue))))
    //console.log("rez:", projectResourceRequests.some(item => item.accountOwner === 'fi'))
    //return projectResourceRequests.filter(obj => Object.values(obj).includes('f'))

    return projectResourceRequests.filter(
      (prr) =>
        prr.projectName.includes(searchValue) ||
        prr.accountOwner.includes(searchValue) ||
        prr.stage.includes(searchValue) ||
        prr.projectDescription.includes(searchValue) ||
        prr.accountName.includes(searchValue)
    );
  };

  useEffect(() => {
    if (searchValue !== '') {
      let result = searchData(searchValue);
      setProjectResourceRequests(result);
    } else {
      setProjectResourceRequests(tempPrrs);
    }
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterData = (prrData) => {
    if (prrFilter !== 'All') {
      //refactor when we will have reviewedBy values
      if (prrFilter === 'Reviewed')
        return prrData.filter(
          (prr) => prr.resolutionStatus === 'Accepted' || prr.resolutionStatus === 'Rejected'
        );
      else return prrData.filter((prr) => prr.resolutionStatus === prrFilter);
    } else {
      return prrData;
    }
  };

  useEffect(() => {
    if (sellerPrrs) {
      let fetchedData = sellerPrrs.getPrrsSubmitter;
      settempPrrs(fetchedData);
      let prrData = filterData(fetchedData);
      console.log(sellerPrrs);
      setProjectResourceRequests(prrData);
    }
  }, [sellerPrrs, prrFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adminPrrs) {
      let fetchedData = adminPrrs.getAllPrrs;
      settempPrrs(fetchedData);
      let prrData = filterData(fetchedData);

      setProjectResourceRequests(prrData);
    }
  }, [adminPrrs, prrFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className={classes.root}>
        <h1 className={classes.heading}>Project Resource Requests</h1>
        <div style={{ textAlign: 'left' }}>
          <FormControl required className={classes.formControl}>
            <Select
              style={{ fontSize: '14px', marginLeft: '5px' }}
              native
              name="stateStatus"
              value={prrFilter}
              inputProps={{
                id: 'stateStatus-native-required',
              }}
              onChange={(e) => setPrrFilter(e.target.value)}
            >
              {/* <option aria-label="None" value="" /> */}
              <option value={'All'}> All</option>
              <option value={'NotReviewed'}> Submitted</option>
              <option value={'Reviewed'}> Reviewed</option>
              <option value={'Accepted'}> Accepted </option>
              <option value={'Rejected'}> Rejected</option>
            </Select>
          </FormControl>
          <InputBase
            style={{ marginLeft: '10px' }}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {projectResourceRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Link to={`/request/${row.id}`} className={classes.link}>
                                {typeof value === 'undefined'
                                  ? 'null'
                                  : column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </Link>
                            </TableCell>
                          );
                        })}
                        <td>
                          <Button
                            component={Link}
                            to={`/request/${row.id}`}
                            variant="contained"
                            color="primary"
                            className={classes.editButton}
                          >
                            View
                          </Button>
                        </td>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 25, 100]}
            component="div"
            count={projectResourceRequests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}
