import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import iolapLogo from '../assets/iolapLogo.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useMutation } from '@apollo/client';
import { LOGIN_USER_MUTATION } from '../GraphQL/Mutations';
const jwt = require('jsonwebtoken');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit">iOLAP</Link> {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoImg: {
    width: '200px',
    height: '200px',
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [formData, setFormData] = useState({});
  const [errorData, setErrorData] = useState({ error: false, errorMessage: {} });

  const [login] = useMutation(LOGIN_USER_MUTATION);

  const loginUser = async (e) => {
    e.preventDefault();

    try {
      const response = await login({
        variables: {
          loginUserInput: formData,
        },
      });

      localStorage.setItem('token', response.data.loginUser.token);

      const user = jwt.decode(response.data.loginUser.token);
      localStorage.setItem('user', JSON.stringify(user));

      window.location = '/';
    } catch (error) {
      if (error.message === 'User not found!') {
        setErrorData({ ...errorData, error: true, errorMessage: { email: 'User not found' } });
        console.log('User not found!');
      } else if (error.message === 'Wrong password!') {
        setErrorData({ ...errorData, error: true, errorMessage: { password: 'Wrong password' } });
        console.log('Wrong password!');
      } else {
        setErrorData({
          ...errorData,
          error: true,
          errorMessage: { unknownError: 'Unknown error accured during login. Please try again.' },
        });
        console.log(error);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div id="logoDiv">
          <img src={iolapLogo} className={classes.logoImg} alt=""></img>
        </div>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={errorData.error && errorData.errorMessage.email !== undefined}
            helperText={errorData.errorMessage.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            s
            autoComplete="current-password"
            error={
              (errorData.error && errorData.errorMessage.password !== undefined) ||
              (errorData.errorMessage.unknownError !== undefined && errorData.error)
            }
            helperText={errorData.errorMessage.password || errorData.errorMessage.unknownError}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />

          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={loginUser}
          >
            Sign In
          </Button>
          
        </form>
      </div>
      <Box >
        <Copyright />
      </Box>
    </Container>
  );
}
