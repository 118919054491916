import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../assets/logo.svg';
//import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as Icons from '@material-ui/icons';
import { Link, Route, Redirect } from 'react-router-dom';
import Request from './Request';
import PrrFormDialog from './NewPrrForm';
import Home from './Home';
import UsersTable from './Users';
import UserProfile from './UserProfile';
import Info from './Info';
import Resources from './Resources';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  appBar: {
    backgroundColor: '#FFF',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: '#000',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: '#0A294C',
    color: 'white',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#0A294C',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {},
  sidebar_icon: {
    color: '#FFF',
  },
  appBar_text_black: {
    color: '#000',
  },
  drawerClose_button: {
    color: '#FFF',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    position: 'absolute',
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
  },
  inputRoot: {
    color: '#000',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  toolbar_icons: {
    position: 'absolute',
    right: '0',
    marginRight: '50px',
    display: 'flex',
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#5584FF',
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user] = useState(JSON.parse(localStorage.getItem('user')));


  const isMenuOpen = Boolean(anchorEl);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location = '/';
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={logout}>Log out</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Icons.Menu />
          </IconButton>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Icons.Search />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value = {searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div> */}

          <div className={classes.toolbar_icons} style={{ alignItems: 'center' }}>
            {
              //user.role === "Seller" &&
              <PrrFormDialog />
            }
            <p style={{ color: 'black' }}> {user.email} </p>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <Icons.AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={logo} alt="logo"></img>
          <IconButton onClick={handleDrawerClose} className={clsx(classes.drawerClose_button)}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button className={classes.listItem} component={Link} to="/">
            <ListItemIcon>
              <Icons.Dashboard className={clsx(classes.sidebar_icon)} />
            </ListItemIcon>
            <ListItemText>PRR List</ListItemText>
          </ListItem>
          {user.role === 'Admin' && (
            <ListItem button className={classes.listItem} component={Link} to="/users">
              <ListItemIcon>
                <Icons.People className={clsx(classes.sidebar_icon)} />
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </ListItem>
          )}
          <ListItem button className={classes.listItem} component={Link} to="/info">
            <ListItemIcon>
              <Icons.InfoSharp className={clsx(classes.sidebar_icon)} />
            </ListItemIcon>
            <ListItemText>Info </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}></main>
      <Route path="/" component={Home} />
      <Route path="/request/:id" component={Request} />
      <Route path="/resources/:prrId" component={Resources} />
      <Route path="/users" render={() => (user.role === 'Seller' ? <Redirect to="/" /> : <UsersTable />)} />
      <Route
        path="/user/:id"
        render={() => (user.role === 'Seller' ? <Redirect to="/" /> : <UserProfile />)}
      />
      <Route path="/info" component={Info} />
    </div>
  );
}
