import React from 'react'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
      width: '80%',
      marginTop: '70px',
      marginLeft: '60px',
    },
    
  });

function Info() {
    
    const classes = useStyles();
    return (
        <div className={classes.root} >
          <div className="description user_content student-version enhanced"><h1 id="add-academy21-team-project---project-assessment-document" className="code-line code-line code-line" data-line="0">Project Resource Request - PRR documentation</h1>

<h2 id="challenge-description" className="code-line code-line code-line" data-line="32">Application Description</h2>
<p className="code-line code-line code-line" data-line="34">The application serves the purpose of<span>&nbsp;</span><strong>submitting and reviewing the PRR(Project Resource Request) document</strong>.</p>
<p className="code-line code-line code-line" data-line="36">What is a PRR document? PRR <span className="ILfuVd"><span className="hgKElc">is defined by <strong>the Project manager to establish the resources needed to execute the work on the project</strong></span></span>. The Seller(PM or his Proxy) will fill up the template followed by StaffOps admin user approval. Both parties are able to update the PRR until it is approved, then only StaffOps admin users can update the records. StaffOps account can see the master inventory, the list of all PRRs, Seller can only see the ones submitted.<br/><br/></p>
<p className="code-line code-line code-line" data-line="42">Project Resource Request content:</p>
<ul>
<li className="code-line code-line code-line" data-line="56">Account Name</li>
<li className="code-line code-line code-line" data-line="56">Account Owner</li>
<li className="code-line code-line code-line" data-line="56">Project Name</li>
<li className="code-line code-line code-line" data-line="56">Project/Need Description</li>
<li className="code-line code-line code-line" data-line="56">Stage/Probability (CRM Map)</li>
<li className="code-line code-line code-line" data-line="56">Resources:
<ul>
<li className="code-line code-line code-line" data-line="56">Resource Location</li>
<li className="code-line code-line code-line" data-line="56">Project Role</li>
<li className="code-line code-line code-line" data-line="56">Internal Role (iOLAP level)</li>
<li className="code-line code-line code-line" data-line="56">Service Area</li>
<li className="code-line code-line code-line" data-line="56">Skill Set</li>
<li className="code-line code-line code-line" data-line="56">Start Date</li>
<li className="code-line code-line code-line" data-line="56">End Date</li>
<li className="code-line code-line code-line" data-line="56">Allocation</li>
<li className="code-line code-line code-line" data-line="56">Example Profile (optional)</li>
</ul>
</li>
</ul>
<p className="code-line code-line code-line" data-line="58">User roles:</p>
<ul>
<li className="code-line code-line code-line" data-line="59">Seller(Proxy) - Can submit PRR, can list own PRRs</li>
<li className="code-line code-line code-line" data-line="61">StaffOps/Admin - can list, modify and delete all PRRs, can manage users (add, delete, assign the role(s) )</li>
</ul>
<h3 id="front-office-for-practitioners" className="code-line code-line code-line" data-line="63">Front Office for Seller</h3>
<p className="code-line code-line code-line" data-line="65">The <strong>Seller</strong> opens a homepage and is represented with a table view of their own submitted PRR document. He can order them by the date submitted and filter by Status (Submitted/Reviewed/Accepted/Rejected). Also, the <strong>Seller</strong> can click on a button for creating a new PRR Document. That form needs to contain the following sections, each of them having input fields:</p>
<p className="code-line code-line code-line" data-line="67">Project Resource Request Info:</p>
<ul>
<li className="code-line code-line code-line" data-line="56">Account Name</li>
<li className="code-line code-line code-line" data-line="56">Account Owner</li>
<li className="code-line code-line code-line" data-line="56">Project Name</li>
<li className="code-line code-line code-line" data-line="56">Project/Need Description</li>
<li className="code-line code-line code-line" data-line="56">Stage/Probability (CRM Map)</li>
<li className="code-line code-line code-line" data-line="56">Resources:
<ul>
<li className="code-line code-line code-line" data-line="56">Resource Location</li>
<li className="code-line code-line code-line" data-line="56">Project Role</li>
<li className="code-line code-line code-line" data-line="56">Internal Role (iOLAP level)</li>
<li className="code-line code-line code-line" data-line="56">Service Area</li>
<li className="code-line code-line code-line" data-line="56">Skill Set</li>
<li className="code-line code-line code-line" data-line="56">Start Date</li>
<li className="code-line code-line code-line" data-line="56">End Date</li>
<li className="code-line code-line code-line" data-line="56">Allocation</li>
<li className="code-line code-line code-line" data-line="56">Example Profile (optional)</li>
</ul>
</li>
</ul>
<p className="code-line code-line code-line" data-line="78">After finishing work on the form, the <strong>Seller</strong> clicks on the button to submit PRR and after that, the <strong>StaffOps</strong> user can edit and update PRR if needed.</p>
<h3 id="front-office-for-reviewers" className="code-line code-line code-line" data-line="80">Front Office for StaffOps</h3>
<p className="code-line code-line code-line" data-line="81">In addition to the <strong>Seller</strong>, the <strong>StaffOps</strong> can review PRRs. To make it possible, the <strong>StaffOps</strong>'s table view should also filter by "Needs Review" and "Already reviewed" statuses.</p>
<p className="code-line code-line code-line" data-line="84">When <strong>StaffOps</strong> opens PRR to review it, PRR is opened in the same form that <strong>Seller</strong> uses for submitting, but it has an additional element for every PRR:</p>
<ul>
<li className="code-line code-line code-line" data-line="85">Change the resolution status (drop-down)</li>
</ul>
<p className="code-line code-line code-line" data-line="88">After finishing work on the form, the <strong>StaffOps</strong> clicks on the button to approve PRR or reject PRR in both cases after that <strong>Seller</strong> cannot edit PRR, he can submit the new one.</p>
<h3 id="back-office-for-admins" className="code-line code-line code-line" data-line="91">Back Office for Admins</h3>
<p className="code-line code-line code-line" data-line="93">Admin should be able to log in to the dashboard with his credentials. On the dashboard, a list of all Users should be shown in form of a table. Expected table columns are:</p>
<ul>
<li className="code-line code-line code-line" data-line="96">User ID</li>
<li className="code-line code-line code-line" data-line="97">First and Last Name</li>
<li className="code-line code-line code-line" data-line="98">Email</li>
<li className="code-line code-line code-line" data-line="99">Service line (ADD, MS, DA)</li>
<li className="code-line code-line code-line" data-line="100">User's SquadLead</li>
<li className="code-line code-line code-line" data-line="101">Role</li>
<li className="code-line code-line code-line" data-line="102">Date Added</li>
</ul>
<p className="code-line code-line code-line" data-line="104">Additionally, <strong>StaffOps</strong> should be able to add, edit and delete users.</p>

</div>
        </div>
    )
}

export default Info
