import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { useAppApolloClient } from './apolloClient';
const AuthorizationContext = React.createContext();

function App() {
  const token = localStorage.getItem('token');
  const client = useAppApolloClient();
  

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <AuthorizationContext.Provider value={token}>
            {!!token ? <PrivateRoute /> : <PublicRoute />}
          </AuthorizationContext.Provider>
        </Router>
      </div>
    </ApolloProvider>
  );
}

export default App;
