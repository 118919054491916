import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PRR } from '../GraphQL/Queries';
import { UPDATE_PRR_MUTATION, DELETE_PRR_MUTATION } from '../GraphQL/Mutations';
import { GET_PRR_REVIEWER } from '../GraphQL/Queries';
import FormControl from '@material-ui/core/FormControl';
import { Select } from '@material-ui/core';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '70px',
    marginLeft: '30px',
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '45ch',
      marginLeft: '30px',
      marginTop: '20px',
    },
  },
  heading: {
    marginLeft: '30px',
    marginRight: '20px',
  },
  buttonDiv: {
    marginTop: '20px',
    marginLeft: '30px',
  },
  button: {
    marginRight: '10px',
  },
  acceptRejectButtonsDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRes: {
    backgroundColor: 'rgb(85, 132, 255)',
    color: 'white',
  },
}));

function Request() {
  const classes = useStyles();
  const { id } = useParams();

  const { data } = useQuery(GET_PRR, {
    variables: {
      getPrrFilter: {
        id: id,
      },
    },
  });

  const [prr, setPrr] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [updatePrr] = useMutation(UPDATE_PRR_MUTATION);
  const [deletePrr] = useMutation(DELETE_PRR_MUTATION);
  const [reviewerTemp, setReviewerTemp] = useState('');

  const { data: reviewer } = useQuery(GET_PRR_REVIEWER, {
    variables: {
      getReviewerForPrrFilter: {
        id: prr.id,
      },
    },
  });

  useEffect(() => {
    try {
      if (reviewer && reviewer.getReviewerForPrr.firstName !== null) {
        setReviewerTemp(`${reviewer.getReviewerForPrr.firstName} ${reviewer.getReviewerForPrr.lastName}`);
      }
    } catch {
      setReviewerTemp('Not yet reviewed');
    }
  }, [reviewer]);

  useEffect(() => {
    if (data) {
      setPrr(data.getPrr);
    }
  }, [data]);

  const updatePrrInfo = (e) => {
    e.preventDefault();
    try {
      if (user.role === 'Admin') {
        updatePrr({
          variables: {
            updatePrrInput: {
              id: prr.id,
              accountName: prr.accountName,
              accountOwner: prr.accountOwner,
              projectName: prr.projectName,
              projectDescription: prr.projectDescription,
              stage: prr.stage,
              probability: prr.probability,
              resolutionStatus: prr.resolutionStatus,
            },
          },
        });
      } else if (user.role === 'Seller') {
        updatePrr({
          variables: {
            updatePrrInput: {
              id: prr.id,
              accountName: prr.accountName,
              accountOwner: prr.accountOwner,
              projectName: prr.projectName,
              projectDescription: prr.projectDescription,
              stage: prr.stage,
              probability: prr.probability,
            },
          },
        });
      }
      successNotification('PRR successfully updated');
      setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      console.log(error);
      errorNotification('Error accured during PRR update.');
    }
  };

  const deleteSinglePrr = (e) => {
    e.preventDefault();
    try {
      deletePrr({
        variables: {
          deletePrrInput: {
            id: prr.id,
          },
        },
      });
      successNotification('PRR successfuly deleted');
      setTimeout(() => (window.location = '/'), 1500);
    } catch (error) {
      console.log(error);
      errorNotification('Error accured during PRR deletion.');
    }
  };

  const successNotification = (message) =>
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const errorNotification = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.acceptRejectButtonsDiv}>
          <h1 className={classes.heading}>Project Resource Request - {prr.id || ''}</h1>
          {/*             
              <Button  variant="outlined"  className={classes.button} style={{color:"green"}}> 
                Accept
              </Button> 
              <Button  color="secondary" variant="outlined"  className={classes.button}> 
                Reject
              </Button>  */}
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              style={{ marginLeft: '40px' }}
            >
              Return
            </Button>
          </Link>
        </div>
        <form noValidate autoComplete="off">
          <div style={{ marginLeft: '30px', display: 'inline-flex', alignItems: 'center' }}>
            {' '}
            <div>Status</div>:
            <FormControl required className={classes.formControl}>
              <Select
                disabled={user.role !== 'Admin'}
                style={{ fontSize: '14px', marginLeft: '5px' }}
                native
                name="resolutionStatus"
                value={prr.resolutionStatus}
                inputProps={{
                  id: 'resolutionStatus-native-required',
                }}
                onChange={(e) => setPrr({ ...prr, resolutionStatus: e.target.value })}
              >
                {/* <option aria-label="None" value="" /> */}
                <option value={'Submitted'}> Submitted</option>
                <option value={'Accepted'}> Accepted </option>
                <option value={'Rejected'}> Rejected</option>
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              required
              disabled={user.role !== 'Admin' && prr.resolutionStatus !== 'NotReviewed'}
              value={prr.accountName || ''}
              onChange={(e) => setPrr({ ...prr, accountName: e.target.value })}
              helperText="Account name"
            />
            <TextField
              required
              disabled={user.role !== 'Admin' && prr.resolutionStatus !== 'NotReviewed'}
              value={prr.accountOwner || ''}
              onChange={(e) => setPrr({ ...prr, accountOwner: e.target.value })}
              helperText="Account owner"
            />
            <TextField
              required
              disabled={user.role !== 'Admin' && prr.resolutionStatus !== 'NotReviewed'}
              value={prr.projectName || ''}
              onChange={(e) => setPrr({ ...prr, projectName: e.target.value })}
              helperText="Project name"
            />
            <TextField
              required
              disabled={user.role !== 'Admin' && prr.resolutionStatus !== 'NotReviewed'}
              value={prr.projectDescription || ''}
              onChange={(e) => setPrr({ ...prr, projectDescription: e.target.value })}
              helperText="Project description"
            />
            <TextField
              required
              disabled={user.role !== 'Admin' && prr.resolutionStatus !== 'NotReviewed'}
              value={prr.stage || ''}
              onChange={(e) => setPrr({ ...prr, stage: e.target.value })}
              helperText="Stage"
            />
            <TextField
              required
              disabled={user.role !== 'Admin' && prr.resolutionStatus !== 'NotReviewed'}
              value={prr.probability || ''}
              onChange={(e) => setPrr({ ...prr, probability: e.target.value })}
              helperText="Probability"
            />
            <TextField
              required
              disabled={true}
              value={reviewerTemp}
              onChange={(e) => setPrr({ ...prr, probability: e.target.value })}
              helperText="Reviewed by"
            />
          </div>
          {/* <div>
            <RequestResourcesContainer data={prrResources} />
          </div> */}
        </form>
        <div className={classes.buttonDiv}>
          <Button
            component={Link}
            to={`/resources/${prr.id}`}
            variant="contained"
            className={classes.buttonRes}
          >
            View resources
          </Button>
        </div>
        <div className={classes.buttonDiv}>
          {(user.role === 'Admin' || prr.resolutionStatus === 'NotReviewed') && (
            <Button onClick={updatePrrInfo} variant="contained" color="primary" className={classes.button}>
              Save
            </Button>
          )}
          {user.role === 'Admin' && (
            <Button onClick={deleteSinglePrr} variant="contained" color="secondary">
              Delete
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default Request;
