import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER } from '../GraphQL/Queries';
import { useParams } from 'react-router-dom';
import { DELETE_USER_MUTATION, UPDATE_USER_MUTATION } from '../GraphQL/Mutations';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormControl from '@material-ui/core/FormControl';
import { Select } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '70px',
    marginLeft: '30px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '45ch',
      marginLeft: '30px',
      marginTop: '20px',
    },
  },
  heading: {
    marginLeft: '30px',
  },
  buttonDiv: {
    marginTop: '50px',
    marginLeft: '30px',
  },
  button: {
    marginRight: '10px',
  },
}));

function UserProfile() {
  const classes = useStyles();
  const { id } = useParams();
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [deleteUser, { error }] = useMutation(DELETE_USER_MUTATION);
  const [loggedUser] = useState(JSON.parse(localStorage.getItem('user')));

  const updateUserInfo = (e) => {
    e.preventDefault();
    try {
      updateUser({
        variables: {
          updateUserInput: {
            id: id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            serviceLine: user.serviceLine,
            squadLead: user.squadLead,
            role: user.role,
          },
        },
      });

      successNotification('User successfuly updated');
      setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      console.log(error);
      errorNotification('Error accured during user update.');
    }
  };
  const deleteSingleUser = async (userId) => {
    try {
      if (userId !== loggedUser.id) {
        await deleteUser({
          variables: {
            deleteUserInput: {
              id: userId,
            },
          },
        });
        successNotification('User deleted successfully');
        // setTimeout(() => window.location.reload(), 1500);
      } else if (userId === loggedUser.id) {
        errorNotification('Not allowed to self delete');
        console.log(error);
      }
    } catch (error) {
      console.log(error);
      errorNotification("Error accured during user deletion. Can't delete user that has existing Prrs");
    }
  };


  const { data } = useQuery(GET_USER, {
    variables: {
      getUserFilter: {
        id: id,
      },
    },
  });

  const [user, setUser] = useState([]);

  useEffect(() => {
    if (data) {
      setUser({ ...data.getUser });
    }
  }, [data]);

  const successNotification = (message) =>
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const errorNotification = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <div className={classes.root}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 className={classes.heading}>User - {user.id || ''}</h1>
          <Link to="/users" style={{ textDecoration: 'none' }}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              style={{ marginLeft: '40px' }}
            >
              Return
            </Button>
          </Link>
        </div>
        <form noValidate>
          <TextField
            required
            value={user.firstName || ''}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            helperText="First name"
          />
          <TextField
            required
            value={user.lastName || ''}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
            helperText="Last name"
          />
          <TextField
            required
            value={user.email || ''}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            helperText="Email"
          />
          <TextField
            required
            type="password"
            defaultValue={''}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            helperText="Password"
          />
          <TextField
            required
            value={user.squadLead || ''}
            onChange={(e) => setUser({ ...user, squadLead: e.target.value })}
            helperText="Squad Lead"
          />
          <FormControl
            required
            className={classes.formControl}
            style={{ width: '350px', marginRight: '25px', marginLeft: '30px' }}
          >
            <InputLabel htmlFor="serviceLine-native-required"> </InputLabel>
            <Select
              native
              value={user.serviceLine}
              helperText="Service line"
              onChange={(e) => setUser({ ...user, serviceLine: e.target.value })}
            >
              <option aria-label="None" value="" />
              <option value={'ADD'}> ADD</option>
              <option value={'MS'}> MS</option>
              <option value={'DA'}> DA</option>
            </Select>
          </FormControl>
          <FormControl
            required
            className={classes.formControl}
            style={{ width: '350px', marginRight: '25px', marginLeft: '30px' }}
          >
            <InputLabel htmlFor="role-native-required"> </InputLabel>
            <Select
              native
              helperText="Role"
              value={user.role}
              onChange={(e) => setUser({ ...user, role: e.target.value })}
            >
              <option aria-label="None" value="" />
              <option value={'Admin'}> Admin</option>
              <option value={'Seller'}> Seller</option>
            </Select>
          </FormControl>
        </form>
        <div className={classes.buttonDiv}>
          <Button onClick={updateUserInfo} variant="contained" color="primary" className={classes.button}>
            Save
          </Button>
          <Link to="/users" style={{ textDecoration: 'none' }}>
            <Button
              onClick={deleteSingleUser}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Delete
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
