import React from 'react'
import StickyHeadTable from './Table'

function Home() {
  

    return (
        <>
            {window.location.pathname === "/" && <StickyHeadTable />}
        </>
    )
}

export default Home
