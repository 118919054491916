import { gql } from '@apollo/client';

export const LOAD_USERS = gql`
  query {
    getUsers {
      id
      firstName
      lastName
      email
      password
      serviceLine
      squadLead
      role
    }
  }
`;

export const GET_USER = gql`
  query getUser($getUserFilter: GetUserInput!) {
    getUser(filter: $getUserFilter) {
      id
      firstName
      lastName
      email
      squadLead
      dateAdded
      password
      serviceLine
      role
    }
  }
`;

export const GET_ALL_PRRS = gql`
  query getAllPrrs {
    getAllPrrs {
      id
      accountName
      accountOwner
      projectName
      projectDescription
      stage
      probability
      dateSubmitted
      stateStatus
      resolutionStatus
      resources {
        id
        resourceLocation
        projectRole
        skillSet
        startDate
        endDate
        allocation
        exampleProfile
        internalRole
        serviceLine
      }
    }
  }
`;

export const GET_PRR = gql`
  query getPrr($getPrrFilter: GetPrrInput!) {
    getPrr(filter: $getPrrFilter) {
      id
      accountName
      accountOwner
      projectName
      projectDescription
      stage
      probability
      dateSubmitted
      stateStatus
      resolutionStatus
      resources {
        id
        resourceLocation
        projectRole
        skillSet
        startDate
        endDate
        allocation
        exampleProfile
        internalRole
        serviceLine
      }
    }
  }
`;

export const GET_PRR_RESOURCES = gql`
  query getResources($getResourcesFilter: GetResourcesInput!) {
    getResources(filter: $getResourcesFilter) {
      id
      resourceLocation
      projectRole
      skillSet
      startDate
      endDate
      allocation
      exampleProfile
      internalRole
      serviceLine
    }
  }
`;

export const GET_PRR_SUBMITTER = gql`
  query getPrrsSubmitter{
    getPrrsSubmitter {
      id
      accountName
      accountOwner
      projectName
      projectDescription
      stage
      probability
      dateSubmitted
      stateStatus
      resolutionStatus
    }
  }
`;

export const GET_PRR_REVIEWER = gql`
  query getReviewer($getReviewerForPrrFilter: GetPrrInput!) {
    getReviewerForPrr(filter: $getReviewerForPrrFilter) {
      firstName
      lastName
    }
  }
`;

export const GET_LOGIN_DATA = gql`
query getLoggedUser {
  getLoggedUser {
    id
    firstName
    lastName
    email
    squadLead
    dateAdded
    serviceLine
    role
  }
}
`

