import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

toast.configure();

export default function ResourceFormDialog({ resourceCallback }) {
  //error messages (other data is addad to object dynamically)
  let [resourceData, setValue] = useState({ error: false, errorMessage: {} });

  //handle open and close
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFormClose = () => {
    //reset error logs and delete all other values
    setValue({ error: true, errorMessage: {} });
    setOpen(false);
  };

  //handle submit
  const handleResourceSubmit = (e) => {
    e.preventDefault();

    let isError = false;
    let percentage = Number(resourceData.allocation);

    if (!(percentage <= 100 && percentage >= 0) || typeof percentage !== 'number') {
      isError = true;
      setValue({
        ...resourceData,
        error: true,
        errorMessage: { allocation: 'Enter a number in range 0-100 (0%-100%) ' },
      });
    } else if (resourceData.startDate > resourceData.endDate) {
      isError = true;
      setValue({
        ...resourceData,
        error: true,
        errorMessage: { startDate: 'Start date cannot be greater than end date' },
      });
    } else if (resourceData.startDate === undefined || resourceData.endDate === undefined) {
      isError = true;
      if (resourceData.startDate === undefined)
        setValue({ ...resourceData, error: true, errorMessage: { startDate: 'Start date is undefined' } });
      else setValue({ ...resourceData, error: true, errorMessage: { startDate: 'End date is undefined' } });
    }
    //do this if everything is valid
    else if (!isError) {
      setValue({ ...resourceData, error: false, errorMessage: {}, allocation: Number(percentage) });
      delete resourceData.error;
      delete resourceData.errorMessage;
      //ovo se salje kao string ako ne pretvorimo u number -- da li pretvarati s ovim pogresnim nacinom kad sa setValue ne radi?
      //ali radi set value na npr allocatio nerroru u if elsu
      resourceData.allocation = percentage;

      resourceCallback(resourceData);
      handleFormClose();
    } else {
      console.log('Unknown error accured');
    }
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const styles = {
    button_newPRR: {
      backgroundColor: '#5584FF',
      color: '#FFF',
      margin: '10px 20px 10px 10px',
      textTransform: 'none',
    },
  };

  return (
    <div>
      <Button variant="contained" style={styles.button_newPRR} onClick={handleClickOpen}>
        New resource
        <AddCircleOutlineIcon style={{ marginLeft: '10px' }} />
      </Button>

      <Dialog open={open} aria-labelledby="form-dialog-title" style={{ textAlign: 'center' }}>
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          New Resource
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="resourceLocation"
            label="Resource location"
            type="text"
            variant="filled"
            fullWidth
            defaultValue=""
            required
            onChange={(e) => setValue({ ...resourceData, resourceLocation: e.target.value })}
          />
          <TextField
            autoFocus
            margin="dense"
            id="projectRole"
            label="Project role"
            type="text"
            variant="filled"
            fullWidth
            defaultValue=""
            required
            onChange={(e) => setValue({ ...resourceData, projectRole: e.target.value })}
          />
          <TextField
            autoFocus
            margin="dense"
            id="skillSet"
            label="Skill set"
            type="text"
            variant="filled"
            fullWidth
            defaultValue=""
            required
            onChange={(e) => setValue({ ...resourceData, skillSet: e.target.value })}
          />
          <TextField
            autoFocus
            margin="dense"
            id="allocation"
            label="Allocation"
            type="text"
            variant="filled"
            fullWidth
            defaultValue=""
            required
            error={resourceData.error && resourceData.errorMessage.allocation !== undefined}
            helperText={resourceData.errorMessage.allocation}
            onChange={(e) => setValue({ ...resourceData, allocation: e.target.value })}
          />
          <TextField
            autoFocus
            margin="dense"
            id="exampleProfile"
            label="Example profile (optional)"
            type="text"
            variant="filled"
            fullWidth
            defaultValue=""
            onChange={(e) => setValue({ ...resourceData, exampleProfile: e.target.value })}
          />

          <FormControl variant="filled" required className={classes.formControl} style={{ width: '47%' }}>
            <InputLabel htmlFor="internalRole-native-required">Internal role</InputLabel>
            <Select
              native
              value={resourceData.internalRole}
              onChange={(e) => setValue({ ...resourceData, internalRole: e.target.value })}
              name="internal role"
              inputProps={{ id: 'internalRole-native-required' }}
            >
              <option aria-label="None" value="" />
              <option value={'Associate'}> Associate</option>
              <option value={'AssociateEngineer'}> Associate engineer</option>
              <option value={'Engineer'}> Engineer</option>
              <option value={'SeniorEngineer'}> Senior engineer</option>
              <option value={'PrincipalEngineer'}> Principal engineer</option>
            </Select>
          </FormControl>

          <FormControl variant="filled" required className={classes.formControl} style={{ width: '47%' }}>
            <InputLabel htmlFor="serviceLine-native-required"> Service line </InputLabel>
            <Select
              native
              value={resourceData.serviceLine}
              onChange={(e) => setValue({ ...resourceData, serviceLine: e.target.value })}
              name="service line"
              inputProps={{ id: 'serviceLine-native-required' }}
            >
              <option aria-label="None" value="" />
              <option value={'ADD'}> ADD</option>
              <option value={'MS'}> MS</option>
              <option value={'DA'}> DA</option>
            </Select>
          </FormControl>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disablePast
              autoOk={true}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="startDate"
              label="Start date"
              value={resourceData.startDate}
              onChange={(e) => setValue({ ...resourceData, startDate: e })}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              error={resourceData.error && resourceData.errorMessage.startDate !== undefined}
              helperText={resourceData.errorMessage.startDate}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disablePast
              autoOk={true}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="endDate"
              label="End date"
              value={resourceData.endDate}
              onChange={(e) => setValue({ ...resourceData, endDate: e })}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              error={resourceData.error && resourceData.errorMessage.endDate !== undefined}
              helperText={resourceData.errorMessage.endDate}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleFormClose} alt="Cancel button" style={{ color: 'red' }}>
            Cancel
          </Button>
          <Button type="submit" onClick={handleResourceSubmit} color="primary" alt="Submit button">
            Add resource
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
