import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(input: $createUserInput) {
      firstName
    }
  }
`;

export const CREATE_PRR_MUTATION = gql`
  mutation createPrr($createPrrInput: CreatePrrInput!) {
    createPrr(input: $createPrrInput) {
      id
      accountName
      accountOwner
      projectName
      projectDescription
      stage
      probability
      dateSubmitted
      stateStatus
      resources {
        id
        resourceLocation
        projectRole
        skillSet
        startDate
        endDate
        allocation
        exampleProfile
        internalRole
        serviceLine
      }
    }
  }
`;

export const LOGIN_USER_MUTATION = gql`
  mutation login($loginUserInput: LoginUserInput!) {
    loginUser(input: $loginUserInput) {
      token
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      id
      firstName
      lastName
      email
      password
      serviceLine
      squadLead
      role
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($deleteUserInput: DeleteUserInput!) {
    deleteUser(input: $deleteUserInput)
  }
`;

export const UPDATE_PRR_MUTATION = gql`
  mutation updatePrr($updatePrrInput: UpdatePrrInput!) {
    updatePrr(input: $updatePrrInput) {
      id
      accountName
      accountOwner
      projectName
      projectDescription
      stage
      probability
      resolutionStatus
    }
  }
`;
export const DELETE_PRR_MUTATION = gql`
  mutation deletePrr($deletePrrInput: DeletePrrInput!) {
    deletePrr(input: $deletePrrInput)
  }
`;

export const UPDATE_RESOURCE_MUTATION = gql`
  mutation updateResource($updateResourceInput: UpdateResourceInput!) {
    updateResource(input: $updateResourceInput) {
      id
      resourceLocation
      projectRole
      internalRole
      serviceLine
      skillSet
      startDate
      endDate
      allocation
      exampleProfile
    }
  }
`;

export const DELETE_RESOURCE_MUTATION = gql`
  mutation deleteResource($deleteResourceInput: DeleteResourceInput!) {
    deleteResource(input: $deleteResourceInput)
  }
`;
