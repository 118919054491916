import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PRR, GET_PRR_RESOURCES } from '../GraphQL/Queries';
import { toast } from 'react-toastify';
import { UPDATE_RESOURCE_MUTATION, DELETE_RESOURCE_MUTATION } from '../GraphQL/Mutations';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '70px',
    marginLeft: '30px',
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '45ch',
      marginLeft: '30px',
      marginTop: '20px',
    },
  },
  heading: {
    marginLeft: '30px',
    marginRight: '20px',
  },
  buttonDiv: {
    marginTop: '20px',
    marginLeft: '30px',
  },
  button: {
    marginRight: '10px',
  },
  acceptRejectButtonsDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRes: {
    backgroundColor: 'rgb(85, 132, 255)',
    color: 'white',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    paddingLeft: '10px',
  },
}));

function Resources() {
  const classes = useStyles();
  const { prrId } = useParams();

  const { data } = useQuery(GET_PRR, {
    variables: {
      getPrrFilter: {
        id: prrId,
      },
    },
  });

  const { data: resourcesData } = useQuery(GET_PRR_RESOURCES, {
    variables: {
      getResourcesFilter: {
        prrId: prrId,
      },
    },
  });

  const [prr, setPrr] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [prrResources, setPrrResources] = useState([]);
  const [updateResource] = useMutation(UPDATE_RESOURCE_MUTATION);
  const [deleteResource] = useMutation(DELETE_RESOURCE_MUTATION);

  useEffect(() => {
    if (data) {
      setPrr(data.getPrr);
    }
  }, [data]);

  useEffect(() => {
    if (resourcesData) {
      setPrrResources(resourcesData.getResources);
    }
  }, [resourcesData]);

  const updateResourceInfo = (resource) => {
    try {
      updateResource({
        variables: {
          updateResourceInput: {
            id: resource.id,
            resourceLocation: resource.resourceLocation,
            projectRole: resource.projectRole,
            internalRole: resource.internalRole,
            serviceLine: resource.serviceLine,
            skillSet: resource.skillSet,
            allocation: resource.allocation,
            exampleProfile: resource.exampleProfile,
          },
        },
      });

      successNotification('Resource successfully updated');
      setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      console.log(error);
      errorNotification('Error accured during PRR update.');
    }
  };

  const deleteSingleResource = (resource) => {
    try {
      deleteResource({
        variables: {
          deleteResourceInput: {
            id: resource.id,
          },
        },
      });

      successNotification('Resource successfully deleted');
      setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      console.log(error);
      errorNotification('Cannot remove resource');
    }
  };

  const successNotification = (message) =>
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const errorNotification = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,

      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const updateSingleResource = (s, value, id) => {
    const newResource = prrResources.map((resource) => {
      if (resource.id !== id) {
        return { ...resource };
      }

      return { ...resource, [s]: value };
    });

    setPrrResources(newResource);
  };
  return (
    <>
      <div className={classes.root}>
        {/* {user.role === 'Admin' && ( */}
        <div className={classes.acceptRejectButtonsDiv}>
          <h1 className={classes.heading}>Resources for: {prr.projectName}</h1>
          {/*             
              <Button  variant="outlined"  className={classes.button} style={{color:"green"}}> 
                Accept
              </Button> 
              <Button  color="secondary" variant="outlined"  className={classes.button}> 
                Reject
              </Button>  */}
          <div className={classes.acceptRejectButtonsDiv}>
            {/*             
              <Button  variant="outlined"  className={classes.button} style={{color:"green"}}> 
                Accept
              </Button> 
              <Button  color="secondary" variant="outlined"  className={classes.button}> 
                Reject
              </Button>  */}
            <Link to={`/request/${prrId}`} style={{ textDecoration: 'none' }}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                style={{ marginLeft: '40px' }}
              >
                Return
              </Button>
            </Link>
          </div>
        </div>
        {/* )} */}

        {prrResources.map((resource) => (
          <div key={resource.id}>
            <TextField
              required
              defaultValue={resource.projectRole || ''}
              onChange={(e) => updateSingleResource('projectRole', e.target.value, resource.id)}
              helperText="Project role"
            />
            <TextField
              required
              value={resource.resourceLocation || ''}
              onChange={(e) => updateSingleResource('resourceLocation', e.target.value, resource.id)}
              helperText="Resource location"
            />

            <TextField
              required
              value={resource.skillSet || ''}
              onChange={(e) => updateSingleResource('skillSet', e.target.value, resource.id)}
              helperText="Skill set"
            />
            <TextField
              required
              value={resource.exampleProfile || ''}
              onChange={(e) => updateSingleResource('exampleProfile', e.target.value, resource.id)}
              helperText="Example profile"
            />
            <TextField
              required
              value={resource.allocation || ''}
              onChange={(e) => updateSingleResource('allocation', e.target.value, resource.id)}
              helperText="Allocation"
            />
            <TextField disabled value={resource.startDate || ''} helperText="Start date" />
            <TextField disabled value={resource.endDate || ''} helperText="End date" />
            <br />
            <FormControl required className={classes.formControl} style={{ width: '20%' }}>
              <InputLabel htmlFor="internalRole-native-required" style={{ paddingLeft: '15px' }}>
                Internal role
              </InputLabel>
              <Select
                native
                value={resource.internalRole}
                onChange={(e) => updateSingleResource('internalRole', e.target.value, resource.id)}
                name="internal role"
                inputProps={{ id: 'internalRole-native-required' }}
              >
                <option aria-label="None" value="" />
                <option value={'Associate'}> Associate</option>
                <option value={'AssociateEngineer'}> Associate engineer</option>
                <option value={'Engineer'}> Engineer</option>
                <option value={'SeniorEngineer'}> Senior engineer</option>
                <option value={'PrincipalEngineer'}> Principal engineer</option>
              </Select>
            </FormControl>

            <FormControl required className={classes.formControl} style={{ width: '20%' }}>
              <InputLabel htmlFor="serviceLine-native-required" style={{ paddingLeft: '15px' }}>
                {' '}
                Service line{' '}
              </InputLabel>
              <Select
                native
                value={resource.serviceLine}
                onChange={(e) => updateSingleResource('serviceLine', e.target.value, resource.id)}
                name="service line"
                inputProps={{ id: 'serviceLine-native-required' }}
              >
                <option aria-label="None" value="" />
                <option value={'ADD'}> ADD</option>
                <option value={'MS'}> MS</option>
                <option value={'DA'}> DA</option>
              </Select>
            </FormControl>
            <div className={classes.buttonDiv}>
              {(user.role === 'Admin' || prr.resolutionStatus === 'NotReviewed') && (
                <Button
                  onClick={function () {
                    updateResourceInfo(resource);
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
              )}
              {user.role === 'Admin' && (
                <Button
                  onClick={function () {
                    deleteSingleResource(resource);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Resources;
