import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Select } from '@material-ui/core';
import { toast } from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CREATE_USER_MUTATION } from '../GraphQL/Mutations';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  addUserButton: {
    backgroundColor: '#5584FF',
    color: '#FFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1070e6',
    },
  },
  addPersonIcon: {
    marginRight: '10px',
  },
}));

export default function AddNewUserForm() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({ error: false, errorMessage: {} });

  const [createUser] = useMutation(CREATE_USER_MUTATION);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const successNotification = () =>
    toast.success('User successfuly created', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const errorNotification = () =>
    toast.error('Error accured during user creation.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    let isError = false;

    const emailIsValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email); //eslint-disable-line

    if (!emailIsValid) {
      isError = true;
      setFormData({ ...formData, error: true, errorMessage: { email: 'Invalid email' } });
    } else if (formData.password.length < 6) {
      isError = true;
      setFormData({ ...formData, error: true, errorMessage: { password: 'Password too short' } });
    } else if (!isError) {
      setFormData({ ...formData, error: false, errorMessage: {} });

      let mutation = formData;
      delete mutation.error;
      delete mutation.errorMessage;

      createUser({
        variables: {
          createUserInput: mutation,
        },
      });

      handleClose();

      successNotification();
      setTimeout(() => window.location.reload(), 1500);
    } else {
      console.log('Unknown error accured');
      errorNotification();
    }
  };

  return (
    <div>
      <Button variant="contained" className={classes.addUserButton} onClick={handleClickOpen}>
        <PersonAddIcon className={classes.addPersonIcon} />
        Add user
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        style={{ textAlign: 'center' }}
      >
        <DialogTitle id="form-dialog-title">Add new user</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <TextField
              required
              autoFocus
              margin="dense"
              id="firstName"
              label="First name"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            />
            <TextField
              margin="dense"
              id="lastName"
              label="Last name"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              name="email"
              type="email"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              error={formData.error && formData.errorMessage.email !== undefined}
              helperText={formData.errorMessage.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <TextField
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              error={formData.error && formData.errorMessage.password !== undefined}
              helperText={formData.errorMessage.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            />
            <TextField
              margin="dense"
              id="squadLead"
              label="Squad lead"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              onChange={(e) => setFormData({ ...formData, squadLead: e.target.value })}
            />
            <FormControl variant="filled" required className={classes.formControl} style={{ width: '47%' }}>
              <InputLabel htmlFor="serviceLine-native-required"> Service line </InputLabel>
              <Select
                native
                name="serviceLine"
                inputProps={{
                  id: 'serviceLine-native-required',
                }}
                onChange={(e) => setFormData({ ...formData, serviceLine: e.target.value })}
              >
                <option aria-label="None" value="" />
                <option value={'ADD'}> ADD</option>
                <option value={'MS'}> MS</option>
                <option value={'DA'}> DA</option>
              </Select>
            </FormControl>
            <FormControl variant="filled" required className={classes.formControl} style={{ width: '47%' }}>
              <InputLabel htmlFor="role-native-required"> Role </InputLabel>
              <Select
                native
                name="role"
                inputProps={{
                  id: 'role-native-required',
                }}
                onChange={(e) => setFormData({ ...formData, role: e.target.value })}
              >
                <option aria-label="None" value="" />
                <option value={'Admin'}> Admin</option>
                <option value={'Seller'}> Seller</option>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="gray">
              Cancel
            </Button>
            <Button type="submit" value="Submit" color="primary" alt="Submit button">
              Add user
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
