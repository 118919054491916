import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import EditResourceCard from './EditResourceCard';
import ResourceFormDialog from './NewResourceForm';
import Icon from '@material-ui/core/Icon';
import { CREATE_PRR_MUTATION } from '../GraphQL/Mutations';
import { useMutation } from '@apollo/client';

toast.configure();

export default function PrrFormDialog() {
  //input data + error messages
  let [prrData, setValue] = useState({ error: false, errorMessage: {}, resources: [] });

  //mutation
  const [createPrr] = useMutation(CREATE_PRR_MUTATION);

  const handleResourceCallback = (newResourceData) => {
    setValue({
      ...prrData,
      error: false,
      errorMessage: {},
      resources: [...prrData.resources, newResourceData],
    });
  };

  const handleCardCallback = (editCardData) => {
    setValue({ ...prrData, error: false, errorMessage: {}, resources: [...prrData.resources, editCardData] });
  };

  //handle opening and closing
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const successNotification = () =>
    toast.success('PRR document successfuly created', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    let percentage = prrData.probability;

    if (!(percentage <= 100 && percentage >= 0) && typeof percentage !== 'number') {
      isError = true;
      setValue({
        ...prrData,
        error: true,
        errorMessage: { probability: 'Enter a number in range 0-100 (0%-100%) ' },
      });
    } else if (prrData.resources.length === 0) {
      isError = true;
      setValue({
        ...prrData,
        error: true,
        errorMessage: { resources: 'PRR document should have at least one resource' },
      });
    } else if (!isError) {
      setValue({ ...prrData, probability: percentage, error: false, errorMessage: {} });
      //ovo ne mijenja vrijednosti
      //setValue({ ...prrData, probability: 12, error: false, errorMessage: {},  })

      //ovako radi
      //resourceData.allocation = percentage

      let mutation = prrData;
      delete mutation.error;
      delete mutation.errorMessage;

      //mutation
      createPrr({
        variables: {
          createPrrInput: mutation,
        },
      });

      handleClose();

      successNotification();
      setTimeout(() => window.location.reload(), 1500);
    } else {
      console.log('Unknown error accured');
    }
  };

  const styles = {
    button_newPRR: {
      backgroundColor: '#5584FF',
      color: '#FFF',
      margin: '10px 20px 10px 10px',
      textTransform: 'none',
    },

    resourcesContainer: {
      width: 'auto',
      minHeight: '100px',
      borderStyle: 'dashed',
      borderColor: '#E2E2E2',
      backgroundColor: 'white',
      margin: '5px',
      display: 'flex',
    },
  };

  return (
    <div>
      <Button variant="contained" style={styles.button_newPRR} onClick={handleClickOpen}>
        New PRR document
      </Button>
      <Dialog open={open} disableEnforceFocus aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          Project Resource Request document
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="accountName"
              label="Account name"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              onChange={(e) => setValue({ ...prrData, accountName: e.target.value })}
            />

            <TextField
              autoFocus
              margin="dense"
              id="accountOwner"
              label="Account owner"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              onChange={(e) => setValue({ ...prrData, accountOwner: e.target.value })}
            />
            <TextField
              autoFocus
              margin="dense"
              id="projectName"
              label="Project Name"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              onChange={(e) => setValue({ ...prrData, projectName: e.target.value })}
            />

            <TextField
              autoFocus
              margin="dense"
              id="projectDescription"
              label="Project Description"
              type="text"
              variant="filled"
              multiline
              rows={5}
              fullWidth
              defaultValue=""
              required
              onChange={(e) => setValue({ ...prrData, projectDescription: e.target.value })}
            />

            <TextField
              autoFocus
              margin="dense"
              id="stage"
              label="Stage"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              onChange={(e) => setValue({ ...prrData, stage: e.target.value })}
            />

            <TextField
              autoFocus
              margin="dense"
              id="probability"
              label="Probability (CRM map)"
              type="text"
              variant="filled"
              fullWidth
              defaultValue=""
              required
              //separete this error messages later
              error={
                (prrData.errorMessage.probability !== undefined && prrData.error) ||
                (prrData.errorMessage.resources !== undefined && prrData.error)
              }
              helperText={prrData.errorMessage.probability || prrData.errorMessage.resources}
              //validationState={isValid ? 'valid' : 'invalid'}
              onChange={(e) => setValue({ ...prrData, probability: e.target.value })}
            />

            <ResourceFormDialog resourceCallback={handleResourceCallback} />

            <div style={styles.resourcesContainer}>
              <EditResourceCard data={prrData.resources} cardCallback={handleCardCallback} />
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} style={{ color: 'red' }} alt="Cancel button">
              Cancel
            </Button>
            <Button type="submit" color="primary" alt="Submit button">
              Submit <Icon className="fa fa-plus-circle" style={{ fontSize: 30 }} />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
