import {  InMemoryCache, ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';


// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

const httpLink = new HttpLink([errorLink, new HttpLink({ uri: 'https://api.prr.iolap.academy/graphql' })]);

const authMiddleware = (authToken) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

const cache = new InMemoryCache({});

export const useAppApolloClient = () => {
  const authToken = localStorage.getItem('token');

  return new ApolloClient({
    //concatenates header with new HttpLink({ uri: 'http://localhost:4000/graphql' 
    link: authMiddleware(authToken).concat(httpLink.options[1]),
    cache,
  });
};