import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import EditResourceFormDialog from './EditResourceForm';

const useStyles = makeStyles({
  content: {
    padding: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '20ch',
  },
  title: {
    fontSize: 14,
  },
  root: {
    display: 'flex',
    float: 'left',
    marginRight: '5px',
  },
  contentDiv: {
    padding: '2px 5px',
  },
});

export default function EditResourceCard(props) {
  let cardInfo = props.data;
  const [open, setOpen] = React.useState(false);

  const handleCallback = (childData) => {
    //updated data from editing
    cardInfo = childData;

    //doesnt show EditResourceForm component anymore
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();

  const renderCard = (card, index) => {
    return (
      <>
        <Card className={classes.root} key={index}>
          <CardContent className={classes.content} style={{ padding: '0px' }}>
            <Button size="small" onClick={handleClickOpen}>
              <div className={classes.contentDiv}>
                {' '}
                <EditIcon fontSize="small" color="primary" />{' '}
              </div>
              {card.projectRole}
            </Button>
          </CardContent>
        </Card>
        {open ? <EditResourceFormDialog parentCallback={handleCallback} cardData={card} /> : null}
      </>
    );
  };

  return <div> {cardInfo.map(renderCard)}</div>;
}
