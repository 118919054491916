import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from './Dashboard'

const PrivateRoute = () => {
  
  return (
    <>
        <Switch>
            <Route path="/" component={Dashboard} />
            <Redirect to='/' />
        </Switch>
    </>
  )
}

export default PrivateRoute;